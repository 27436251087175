import * as Sentry from '@sentry/react';

import { MiscUtils } from '../../../utils/MiscUtils';
import { environment } from '../../config/environment';
import { EnvironmentName } from '../../constants/Environment';

const isProduction = [EnvironmentName.PRODUCTION, EnvironmentName.CANARY].includes(environment.Name);

interface IMetrics {
    'platform.resource.all.count': number;
    'platform.resource.js.count': number;
    'platform.resource.css.count': number;
    'platform.resource.image.count': number;
    'platform.resource.video.count': number;
    'platform.resource.other.count': number;
    'platform.resource.firstParty.count': number;
    'platform.resource.thirdParty.count': number;
    'platform.domain.thirdParty.count': number;
    'platform.resource.all.size': number;
    'platform.resource.js.size': number;
    'platform.resource.css.size': number;
    'platform.resource.image.size': number;
    'platform.resource.video.size': number;
    'platform.resource.other.size': number;
    'platform.resource.firstParty.size': number;
    'platform.resource.thirdParty.size': number;
}

Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: BUILD_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ['ark-com-app-key'],
            behaviour: 'drop-error-if-contains-third-party-frames',
        }),
    ],
    sampleRate: isProduction ? 0.1 : 1.0,
    tracesSampleRate: isProduction ? 0.1 : 1.0,
    replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
    replaysOnErrorSampleRate: isProduction ? 0.1 : 1.0,
});

if (!MiscUtils.isServer) {
    (window as any).__RUM__ = { ...Sentry };
}

export const captureSentryMessage = (tag: string, breadcrumb: Sentry.Breadcrumb) => {
    Sentry.addBreadcrumb(breadcrumb);
    Sentry.captureMessage(tag, { tags: { [tag]: 'true' } });
};

export const trackResourcesSentry = (tag: string) => {
    Sentry.captureMessage(tag, { tags: sentryTrackPageResources() });
};

const getElementCount = (tag: string) => document.getElementsByTagName(tag).length;

if (!MiscUtils.isServer) {
    Sentry.setTag('platform.iframe.count', getElementCount('iframe'));
    Sentry.setTag('platform.displayAd.count', getElementCount('display-ad-component'));
    Sentry.setTag('platform.deviceType', getDeviceType());
    Sentry.setTag('platform.userAgent', navigator.userAgent);
    Sentry.setTag('platform.resolution', `${window.screen.width}x${window.screen.height}`);
}

export const sentryTrackPageResources = () => {
    if (MiscUtils.isServer) {
        return;
    }

    if (!('performance' in window) || !('getEntriesByType' in window.performance)) {
        return;
    }

    const firstPartyDomains = ['arkadium.com', 'arenacloud.cdn.arkadiumhosted.com', 'sentry.arkadiumhosted.com'];
    const thirdPartyDomains = new Set();
    const imageExtensions = new Set(['jpg', 'gif', 'png', 'bmp', 'ico', 'svg', 'tiff', 'jpeg', 'webp']);
    const videoExtensions = new Set(['mp4', 'avi', 'mov', 'webm', 'mkv']);
    let counts = {
        all: 0,
        css: 0,
        js: 0,
        image: 0,
        video: 0,
        other: 0,
        firstParty: 0,
        thirdParty: 0,
        thirdPartyDomains: 0,
    };
    let sizes = {
        all: 0,
        css: 0,
        js: 0,
        image: 0,
        video: 0,
        other: 0,
        firstParty: 0,
        thirdParty: 0,
    };
    const resources = performance.getEntriesByType('resource');

    for (const resource of resources) {
        counts.all++;
        const url = new URL(resource.name);
        const hostname = url.hostname;
        const fileType = url.pathname.split('.').pop() || '';
        const transferSize = resource.transferSize / 1000;

        sizes.all += transferSize;

        const isFirstParty = firstPartyDomains.includes(hostname);

        if (isFirstParty) {
            counts.firstParty++;
            sizes.firstParty += transferSize;
        } else {
            counts.thirdParty++;
            sizes.thirdParty += transferSize;

            if (!thirdPartyDomains.has(hostname)) {
                thirdPartyDomains.add(hostname);
                counts.thirdPartyDomains++;
            }
        }

        if (fileType === 'js') {
            counts.js++;
            sizes.js += transferSize;
        } else if (fileType === 'css') {
            counts.css++;
            sizes.css += transferSize;
        } else if (imageExtensions.has(fileType)) {
            counts.image++;
            sizes.image += transferSize;
        } else if (videoExtensions.has(fileType)) {
            counts.video++;
            sizes.video += transferSize;
        } else {
            counts.other++;
            sizes.other += transferSize;
        }
    }

    const metrics = {
        'platform.resource.all.count': counts.all,
        'platform.resource.js.count': counts.js,
        'platform.resource.css.count': counts.css,
        'platform.resource.image.count': counts.image,
        'platform.resource.video.count': counts.video,
        'platform.resource.other.count': counts.other,
        'platform.resource.firstParty.count': counts.firstParty,
        'platform.resource.thirdParty.count': counts.thirdParty,
        'platform.domain.thirdParty.count': counts.thirdPartyDomains,
        'platform.resource.all.size': sizes.all,
        'platform.resource.js.size': sizes.js,
        'platform.resource.css.size': sizes.css,
        'platform.resource.image.size': sizes.image,
        'platform.resource.video.size': sizes.video,
        'platform.resource.other.size': sizes.other,
        'platform.resource.firstParty.size': sizes.firstParty,
        'platform.resource.thirdParty.size': sizes.thirdParty,
    };

    return formatInputData(metrics);
};

const formatInputData = (data: IMetrics) => {
    const formattedData = {};

    for (const [key, value] of Object.entries(data)) {
        formattedData[key] = value;
    }

    return formattedData;
};

function getDeviceType() {
    const ua = navigator.userAgent;

    if (/Mobi|Android/i.test(ua)) {
        return 'Mobile';
    }

    if (/Tablet|iPad/i.test(ua)) {
        return 'Tablet';
    }

    return 'Desktop';
}
